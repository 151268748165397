import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { Spin, Icon } from "antd";
import { Button, message } from "antd";
import DataGrid from "../../components/dataGrid";
import FormModal from "../../components/formModal";
import { isEmpty } from "../../lib/utils";
import Session, { getItem } from "../../lib/session";
import filterFactory, {
  textFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import service from "../../services/life";
import { schema } from "../../formSchema/Life/lifeSchema";
import { BaseMediaUrl } from "../../config/configUrl";

function dateFormatter(cell, row) {
  return (
    <span>
      {" "}
      {new Date(cell).toLocaleDateString("en-GB") +
        " " +
        new Date(cell).toLocaleTimeString("en-GB")}{" "}
    </span>
  );
}

function imageFormatter(cell, row) {
  return <img src={BaseMediaUrl + cell} className="datagrid-image" />;
}

const columns = [
  {
    dataField: "post_date",
    text: "วันที่ประกาศ",
    formatter: dateFormatter,
    sort: true,
  },
  {
    dataField: "title",
    text: "หัวข้อ",
  },
  {
    dataField: "image",
    text: "รูปภาพ",
    formatter: imageFormatter,
  },
  {
    dataField: "description",
    text: "รายละเอียด",
    sort: true,
  },
];

class Life extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectlist: [],
      limit: 20,
      offset: 0,
      filter_string: "",
      totalSize: 0,
      sizePerPage: 20,
      page: 1,
      loading: true,
      formAllState: {
        Create: "Create",
        Update: "Update",
        Show: "Show",
      },
    };
  }

  componentDidMount() {
    if (isEmpty(getItem(Session.header))) {
      this.props.history.push("/login");
    } else {
      setTimeout(() => {
        if (this.props.auth.token !== null) {
          this.init_data(this.props.auth.token);
        }
      }, 800);
    }
  }

  init_data = (_token) => {
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string,
    };
    this.spinLoading(true);
    this.init_data_option(this.props.auth.token, obj).then((res, err) => {
      if (res.state) {
        this.setState({
          objectlist: res.data.data.results,
          totalSize: res.data.data.count,
        });
        this.spinLoading(false);
      } else {
        console.log("get data error !!");
      }
    });
  };

  init_data_option = (_token, _option) => {
    return new Promise((resolve, reject) => {
      service.getListOption(_token, _option).then((res, err) => {
        if (res.status) {
          if (res.data.status === 200) {
            resolve({ state: true, data: res.data });
          }
        } else {
          if (res.error.response.status === 401) {
            this.props.history.push("/login");
          }
        }
      });
    });
  };

  funcAdd = (_dataObj) => {
    service.AddNews(this.props.auth.token, _dataObj).then((res, err) => {
      console.log("res => ", res);
      if (res.status) {
        console.log("success");
        message.success("เพิ่มข้อมูลสำเร็จ");
        this.myChildForAdd.handleCancel();
        this.init_data(this.props.auth.token);
      } else {
        console.log("error");
        message.error("เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ");
      }
    });
  };

  funcFullUpdate = (_dataObj) => {
    service.FullUpdateNews(this.props.auth.token, _dataObj).then((res, err) => {
      console.log("res => ", res);
      if (res.status) {
        console.log("success");
        message.success("แก้ไขข้อมูลสำเร็จ");
        this.myChildFormModal.handleCancel();
      } else {
        console.log("error");
        message.error("เกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาลองใหม่อีกครั้งค่ะ");
      }
    });
  };

  funcCallbackDelete = (_id) => {
    // console.log("funcCallbackDelete on id => ",_id)
    service.DeleteNews(this.props.auth.token, _id).then((res, err) => {
      console.log("res => ", res);
      if (res.status) {
        console.log("success");
        message.success("ลบข้อมูลสำเร็จ");
        this.myChildFormModal.handleCancel();
        this.init_data(this.props.auth.token);
      } else {
        console.log("error");
        message.error("เกิดข้อผิดพลาดในการลบข้อมูล กรุณาลองใหม่อีกครั้งค่ะ");
      }
    });
  };

  handleClickAdd = () => {
    let dataForChild = {
      dataObj: {
        results: schema.dataStructure,
        dataDefault: schema,
        schema: schema,
        idx: null,
      },
      formObj: {
        formState: this.state.formAllState.Create,
        formAllState: this.state.formAllState,
      },
    };

    this.myChildForAdd.initFormModal(dataForChild);
  };

  funcCallBackModalAdd = (_dataCallBack) => {
    this.funcAdd(_dataCallBack);
  };

  funcCallBackModalUpdate = (_dataCallBack) => {
    this.funcFullUpdate(_dataCallBack);
  };

  modalMessageCallback = () => {
    console.log("modalMessageCallback");
    this.myChildForAdd.handleCancel();
    this.myChildFormModal.handleCancel();
  };

  DataGridCallback = (_row, _index) => {
    this.formatData(schema.dataStructure, _row).then((res, err) => {
      let dataForChild = {
        dataObj: {
          results: res,
          dataDefault: res,
          schema: schema,
          idx: _index,
          canDelete: true,
        },
        formObj: {
          formState: this.state.formAllState.Update,
          formAllState: this.state.formAllState,
        },
      };

      this.myChildFormModal.initFormModal(dataForChild);
    });
  };

  PaginationCallback = (type, _limit, _offset, _filter_string, _page) => {
    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string,
      loading: false,
    };

    if (type === "filter" || type === "pagination" || type === "sort") {
      this.spinLoading(true);
      this.init_data_option(this.props.auth.token, obj).then((res, err) => {
        if (res.state) {
          this.setState({
            limit: _limit,
            offset: _offset,
            page: _page,
            objectlist: res.data.data.results,
          });
          this.spinLoading(false);
        } else {
          console.log("get data error !!");
        }
      });
    }
  };

  spinLoading = (_bool) => {
    this.setState({ loading: _bool });
  };
  formatData = (_dataObj, _row) => {
    return new Promise((resolve, reject) => {
      let results = {};
      Object.keys(_dataObj).forEach((row, index) => {
        results[row] = _row[row];

        if (Object.keys(_dataObj).length - 1 === index) {
          resolve(results);
        }
      });
    });
  };

  render() {
    const { objectlist, totalSize, page, limit, loading } = this.state;

    return (
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <h2>ข้อมูลข่าวสาร</h2>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <Button
              onClick={() => {
                this.handleClickAdd();
              }}
              style={{ float: "right" }}
              type="primary"
            >
              เพิ่มรายการ
            </Button>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            {/* {objectlist.length === 0 ? null : (
              <div>
                <FormModal
                  callback={this.funcCallBackModalAdd}
                  title="เพิ่มข่าวสาร"
                  onRef={(refs) => (this.myChildForAdd = refs)}
                />
                <FormModal
                  title="แก้ไขข้อมูลข่าวสาร"
                  callbackDel={this.funcCallbackDelete}
                  onRef={(refs) => (this.myChildFormModal = refs)}
                  callback={this.funcCallBackModalUpdate}
                />
              </div>
            )} */}
            <div>
              <FormModal
                callback={this.funcCallBackModalAdd}
                title="เพิ่มข่าวสาร"
                onRef={(refs) => (this.myChildForAdd = refs)}
              />
              <FormModal
                title="แก้ไขข้อมูลข่าวสาร"
                callbackDel={this.funcCallbackDelete}
                onRef={(refs) => (this.myChildFormModal = refs)}
                callback={this.funcCallBackModalUpdate}
              />
            </div>
            <Spin spinning={loading} delay={300} tip="Loading...">
              <DataGrid
                callback={this.DataGridCallback}
                totalSize={totalSize}
                page={page}
                limit={limit}
                paginationCallback={this.PaginationCallback}
                columns={columns}
                dataList={objectlist}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (token) => {
      // dispatch(busAction.fetchBusData(token))
    },
    updateBusData: (dataObj) => {
      // console.log("updateBusData => ",dataObj)
      // console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
      // dispatch(busAction.fetchBusAgain(dataObj))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Life);
