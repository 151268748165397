import React, { Component } from "react";
import { Modal, Button } from "antd";
import { Input } from "antd";
import { DatePicker, AutoComplete } from "antd";
import { Row, Col } from "antd";
import { Avatar } from "antd";
import { isEmpty } from "../../lib/utils";
const { TextArea } = Input;
const { Option } = AutoComplete;

class FormGenerate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      dataFromParent: props.dataFromParent,
      formObj: props.dataFromParent.dataObj.results,
      dataInForm: {},
      imageShow: {
        name: undefined,
        file: undefined,
        contentImgShow: undefined,
      },
    };
  }

  componentDidMount() {
    // console.log(" => ",this.props.dataFromParent)
    //console.log(this.state.optiondata)
  }

  handleInputChange = (_key, _type, _event) => {
    let data = this.state.dataInForm;
    if (_type === "string") {
      data[_key] = _event.target.value;
      this.setState({ dataInForm: data });
    } else {
      if (_event.target.value !== "") {
        data[_key] = parseInt(_event.target.value);
        this.setState({ dataInForm: data });
      } else {
        data[_key] = _event.target.value;
        this.setState({ dataInForm: data });
      }
    }
  };

  handleInputSelectImageChange = (event, key) => {
    let dataObj = this.state.dataInForm;

    let reader = new FileReader();
    let selectedFile = undefined;
    selectedFile = event.target.files[0];

    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      let imageObj = {
        name: selectedFile.name,
        file: reader.result,
        contentImgShow: selectedFile.name,
      };
      console.log(reader.result);
      dataObj[key] = reader.result;
      this.setState({ imageShow: imageObj, dataInForm: dataObj });
    };
  };

  handleInputDataChange = (dateString, key) => {
    let dataObj = this.state.dataInForm;
    let date = new Date(dateString);
    let dateStr = date.toISOString();
    dataObj[key] = dateStr;
    this.setState({ dataInForm: dataObj });
  };

  handleInputOnlyDateChange = (dateString, key) => {
    let dataObj = this.state.dataInForm;
    dataObj[key] = dateString;
    this.setState({ dataInForm: dataObj });
  };

  generateInputForm = (_data, _type, _index, _values, _active) => {
    if (_data.key !== "id") {
      if (_type === "integer") {
        return (
          <div key={`input_${_index}`}>
            <Col style={{ textAlign: "right" }} span={10}>
              <span>{_data.label} : </span>
            </Col>
            <Col style={{ textAlign: "left" }} span={14}>
              {_active ? (
                <Input
                  style={{ width: 300 }}
                  onChange={(event) => {
                    this.handleInputChange(_data.key, _type, event);
                  }}
                  value={_values}
                  placeholder={_data.label}
                  type="number"
                />
              ) : (
                <Input
                  disabled
                  style={{ width: 300 }}
                  value={_values}
                  placeholder={_data.label}
                  type="number"
                />
              )}
            </Col>
          </div>
        );
      } else if (_type === "string") {
        return (
          <div key={`input_${_index}`}>
            <Col style={{ textAlign: "right" }} span={10}>
              <span>{_data.label} : </span>
            </Col>
            <Col style={{ textAlign: "left" }} span={14}>
              {_active ? (
                <Input
                  style={{ width: 300 }}
                  onChange={(event) => {
                    this.handleInputChange(_data.key, _type, event);
                  }}
                  value={_values}
                  placeholder={_data.label}
                  type="text"
                />
              ) : (
                <Input
                  disabled
                  style={{ width: 300 }}
                  value={_values}
                  placeholder={_data.label}
                  type="text"
                />
              )}
            </Col>
          </div>
        );
      } else if (_type === "image upload") {
        return (
          <div key={`input_${_index}`}>
            <Col style={{ textAlign: "right" }} span={24}>
              {this.state.imageShow.file === undefined ? null : (
                <div style={{ textAlign: "center" }}>
                  <br />
                  <Avatar
                    shape="square"
                    size={120}
                    src={this.state.imageShow.file}
                  />
                  <br />
                  <br />
                </div>
              )}
            </Col>
            <Col style={{ textAlign: "right" }} span={10}>
              <span>{_data.label} : </span>
            </Col>
            <Col style={{ textAlign: "left" }} span={14}>
              {_active ? (
                <input
                  accept="image/*"
                  onChange={(e) => {
                    this.handleInputSelectImageChange(e, _data.key);
                  }}
                  style={{ width: 300 }}
                  type="file"
                />
              ) : (
                <input
                  disabled
                  accept="image/*"
                  style={{ width: 300 }}
                  type="file"
                />
              )}
            </Col>
          </div>
        );
      } else if (_type === "datetime") {
        return (
          <div key={`input_${_index}`}>
            <Col style={{ textAlign: "right" }} span={10}>
              <span>{_data.label} : </span>
            </Col>
            <Col style={{ textAlign: "left" }} span={14}>
              {_active ? (
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={(date, dateString) => {
                    this.handleInputDataChange(dateString, _data.key);
                  }}
                  style={{ width: 300 }}
                />
              ) : (
                <DatePicker disabled style={{ width: 300 }} />
              )}
            </Col>
          </div>
        );
      } else if (_type === "date") {
        return (
          <div key={`input_${_index}`}>
            <Col style={{ textAlign: "right" }} span={10}>
              <span>{_data.label} : </span>
            </Col>
            <Col style={{ textAlign: "left" }} span={14}>
              {_active ? (
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={(date, dateString) => {
                    this.handleInputOnlyDateChange(dateString, _data.key);
                  }}
                  style={{ width: 300 }}
                />
              ) : (
                <DatePicker disabled style={{ width: 300 }} />
              )}
            </Col>
          </div>
        );
      }
    }
  };

  formatDataBus = (_dataObj, _row) => {
    return new Promise((resolve, reject) => {
      let results = {};
      Object.keys(_dataObj).forEach((row, index) => {
        results[row] = _row[row];

        if (Object.keys(_dataObj).length - 1 === index) {
          resolve(results);
        }
      });
    });
  };

  handleClickCancel = () => {
    this.formatDataBus(
      this.state.dataFromParent.dataObj.schema.dataStructure,
      this.state.formObj
    ).then((res, err) => {
      this.setState({ dataInForm: res });
      this.props.closeModel();
    });
  };

  funcMergeDataDefaultAndDataCurrent = () => {
    let dataDefault = this.state.dataFromParent.dataObj.results;
    let dataCurrent = this.state.dataInForm;
    let newObj = Object.assign(dataDefault, dataCurrent);

    // console.log("dataDefault => ",dataDefault)
    // console.log("dataInForm => ",this.state.dataInForm)

    return newObj;
  };

  handleClickSave = () => {
    const newObj = this.funcMergeDataDefaultAndDataCurrent();
    setTimeout(() => {
      this.props.callback(newObj);
    }, 1000);
  };

  handleClickEdit = () => {
    this.setState({ active: !this.state.active });
  };

  handleClickCheck = () => {
    console.log("dataFromParent => ", this.state.dataFromParent.dataObj);
    console.log("dataInForm => ", this.state.dataInForm);
  };

  render() {
    const { dataFromParent, formObj, active, dataInForm } = this.state;

    return (
      <div>
        {Object.keys(dataFromParent.dataObj.schema.dataStructure).map(
          (row, index) => {
            return (
              <div key={`input_${index}`}>
                <Row gutter={32} style={{ marginTop: 15, marginBottom: 15 }}>
                  {this.generateInputForm(
                    dataFromParent.dataObj.schema.properties[row],
                    dataFromParent.dataObj.schema.properties[row].type,
                    index,
                    dataInForm[row],
                    active
                  )}
                </Row>
              </div>
            );
          }
        )}
        <Row gutter={32} style={{ marginTop: 15, marginBottom: 15 }}>
          <Col style={{ textAlign: "right" }} xs={{ span: 16, offset: 4 }}>
            <div style={{ marginRight: 20 }}>
              <Button
                style={{ width: 100 }}
                type="danger"
                onClick={() => {
                  this.handleClickCancel();
                }}
              >
                ยกเลิก
              </Button>
              {"  "}
              <Button
                style={{ width: 100 }}
                onClick={() => {
                  this.handleClickSave();
                }}
                type="primary"
              >
                บันทึก
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default FormGenerate;
