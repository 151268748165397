import axios from "axios";
import { BaseUrl } from "../config/configUrl";
import { Key } from "../config/apikey";

const apiVersion = "v2/";

var Service = {
  getList: (_token, _option) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + _token,
      apikey: Key,
    };
    console.log("option", _option);
    let url =
      "https://smartbus.kku.ac.th/bus-people-counter-api/6/?q=people_total > 0 and time < '" +
      _option.date +
      " 16:59:59' and time >= '" +
      _option.date +
      "' order by time desc&limit=1000&offset=0";
    console.log("url", url);
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve({ status: true, data: response });
        })
        .catch((error) => {
          resolve({ status: false, error: error });
        });
    });
  },

  getListOption: (_token, _option) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + _token,
      apikey: Key,
    };

    return new Promise((resolve, reject) => {
      axios
        .get(BaseUrl + apiVersion + "transaction-coins/", { headers: headers })
        .then((response) => {
          resolve({ status: true, data: response });
        })
        .catch((error) => {
          resolve({ status: false, error: error });
        });
    });
  },
};

export default Service;
