import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { Spin } from "antd";
import { Button, Select, TimePicker } from "antd";
import DataGrid from "../../components/dataGrid";
import { isEmpty } from "../../lib/utils";
import service from "../../services/reportcardwithline";
import Session, { getItem } from "../../lib/session";
import filterFactory, {
  textFilter,
  dateFilter,
  selectFilter,
  Comparator,
  customFilter,
} from "react-bootstrap-table2-filter";
import DateRange from "../../components/dateRange";
import service2 from "../../services/reportKKUtransaction";
const { Option } = Select;

const format = "HH:mm";

function dateFormatter(cell, row) {
  return <span> {new Date(cell).toLocaleDateString("en-GB")} </span>;
}
function timeFormatter(cell, row) {
  return <span> {new Date(cell).toLocaleTimeString("en-GB")} </span>;
}

var card_type_dict = {
  1: "บัตรนักศึกษา",
  2: "บัตรบุคลากร",
};

function cardTypeFormatter(cell, row) {
  return card_type_dict[cell];
}

const selectSexOptions = {
  ชาย: "ชาย",
  หญิง: "หญิง",
};

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    hidden: true,
  },
  {
    dataField: "time",
    text: "วันที่ขึ้นรถ",
    formatter: dateFormatter,
    filter: dateFilter(),
    sort: true,
  },
  {
    dataField: "time",
    formatter: timeFormatter,
    text: "เวลาที่ขึ้นรถ",
    sort: true,
  },
  {
    dataField: "bus",
    text: "เบอร์รถ",
    filter: textFilter(),
  },
  {
    dataField: "people_total",
    text: "จำนวนคน",
  },
  {
    dataField: "men",
    text: "ชาย",
  },
  {
    dataField: "woman",
    text: "หญิง",
  },
];

class ReportCntByTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectlist: [],
      limit: 20,
      offset: 0,
      filter_string: "",
      totalSize: 0,
      sizePerPage: 20,
      page: 1,
      loading: true,
      reportType: "",
      startdate: "2020-12-01",
      enddate: "",
      starttime: "00:00",
      endtime: "23:59",
    };
  }

  componentDidMount() {
    if (isEmpty(getItem(Session.header))) {
      this.props.history.push("/login");
    } else {
      let tmp_date = new Date();
      let curr_date =
        tmp_date.getFullYear() +
        "-" +
        (tmp_date.getMonth() + 1) +
        "-" +
        tmp_date.getDate();
      this.setState({
        enddate: curr_date,
      });
      setTimeout(() => {
        if (this.props.auth.token !== null) {
          this.init_data(this.props.auth.token);
          this.init_data2(this.props.auth.token);
        }
      }, 800);
    }
  }

  init_data = (_token) => {
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      starttime: this.state.starttime,
      endtime: this.state.endtime,
    };
    this.spinLoading(true);
    this.init_data_option(this.props.auth.token, obj).then((res, err) => {
      if (res.state) {
        console.log(res.data);
        this.setState({
          objectlist: res.data.data,
        });
        this.spinLoading(false);
      } else {
        console.log("get data error !!");
      }
    });
  };

  init_data2 = (_token) => {
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string,
    };
    this.spinLoading(true);
    this.init_data_option2(this.props.auth.token, obj).then((res, err) => {
      if (res.state) {
        this.setState({
          totalSize: res.data.data.count,
          objectlist: res.data.data.results,
        });
        this.spinLoading(false);
      } else {
        console.log("get data error !!");
      }
    });
  };

  init_data_option = (_token, _option) => {
    console.log("1");
    return new Promise((resolve, reject) => {
      service.getListOption(_token, _option).then((res, err) => {
        if (res.status) {
          if (res.data.status === 200) {
            resolve({ state: true, data: res.data });
          }
        } else {
          if (res.error.response.status === 401) {
            this.props.history.push("/login");
          }
        }
      });
    });
  };

  init_data_option2 = (_token, _option) => {
    console.log("2");
    console.log(this.state.reportType);
    return new Promise((resolve, reject) => {
      service2
        .getListOption(_token, _option, this.state.reportType)
        .then((res, err) => {
          if (res.status) {
            if (res.data.status === 200) {
              resolve({ state: true, data: res.data });
            }
          } else {
            if (res.error.response.status === 401) {
              this.props.history.push("/login");
            }
          }
        });
    });
  };

  DataGridCallback = (_row, _index) => {};

  PaginationCallback = (type, _limit, _offset, _filter_string, _page) => {
    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string,
      loading: false,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      starttime: this.state.starttime,
      endtime: this.state.endtime,
    };
    if (type === "filter" || type === "pagination" || type === "sort") {
      this.spinLoading(true);
      this.init_data_option2(this.props.auth.token, obj).then((res, err) => {
        console.log("res", res);
        if (res.state) {
          this.setState({
            limit: _limit,
            offset: _offset,
            page: _page,
            totalSize: res.data.data.count,
            objectlist: res.data.data.results,
          });
          this.spinLoading(false);
        } else {
          console.log("get data error !!");
        }
      });
    }
  };

  dataRangeCallback = (_dateCallback) => {
    this.setState({
      startdate: _dateCallback.start_date,
      enddate: _dateCallback.end_date,
    });
    this.init_data(this.props.auth.token);
  };

  spinLoading = (_bool) => {
    this.setState({ loading: _bool });
  };

  render() {
    const { objectlist, totalSize, page, limit, loading } = this.state;

    return (
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <h2>ข้อมูลการขึ้นรถตามช่วงเวลา</h2>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <h6>สามารถเลือกเวลาที่ต้องการดูข้อมูลได้ที่นี่</h6>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <TimePicker
              placeholder={"เวลาเริ่มต้น"}
              onChange={(time, timeString) =>
                this.setState({ starttime: timeString })
              }
              format={format}
            />
          </Col>
          <Col span={1}>ถึง</Col>
          <Col span={5}>
            <TimePicker
              placeholder={"เวลาสิ้นสุด"}
              onChange={(time, timeString) =>
                this.setState({ endtime: timeString })
              }
              format={format}
            />
            <Button
              type="primary"
              icon="search"
              onClick={() => this.init_data(this.props.auth.token)}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}></Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <Spin spinning={loading} delay={300} tip="Loading...">
              <DataGrid
                callback={this.DataGridCallback}
                totalSize={totalSize}
                page={page}
                limit={limit}
                paginationCallback={this.PaginationCallback}
                columns={columns}
                dataList={objectlist}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (token) => {
      // dispatch(busAction.fetchBusData(token))
    },
    updateBusData: (dataObj) => {
      // console.log("updateBusData => ",dataObj)
      // console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
      // dispatch(busAction.fetchBusAgain(dataObj))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCntByTime);
