import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { Spin } from "antd";
import { DatePicker } from "antd";
import service from "../../services/transactionCoinForChart";
import Session, { getItem } from "../../lib/session";
import { isEmpty } from "../../lib/utils";
import ChartColumn from "../../components/chartColumn";

const chartdata = [
  {
    xLine: "count",
    color: "#F2A04E",
  },
];
class CustomerCNTChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectlist: [],
      date: "",
      totalSize: 0,
      GroupData: [],
      loading: false,
    };
  }

  componentDidMount() {
    if (isEmpty(getItem(Session.header))) {
      this.props.history.push("/login");
    } else {
      let tmp_date = new Date();
      console.log("tmp_date", tmp_date);
      let DD = tmp_date.getDate();
      if (tmp_date.getDate() < 9) {
        DD = "0" + tmp_date.getDate();
      }
      let curr_date =
        tmp_date.getFullYear() + "-" + (tmp_date.getMonth() + 1) + "-" + DD;
      this.setState({
        date: curr_date,
      });
      setTimeout(() => {
        if (this.props.auth.token !== null) {
          this.init_data(this.props.auth.token);
        }
      }, 800);
    }
  }

  spinLoading = (_bool) => {
    this.setState({ loading: _bool });
  };

  init_data = (_token) => {
    this.spinLoading(true);
    let obj = {
      date: this.state.date,
    };
    this.init_data_option(this.props.auth.token, obj).then((res, err) => {
      if (res.state) {
        this.setState({
          objectlist: res.data.data.results,
        });
        this.addtime();
      } else {
        console.log("get data error !!");
      }
    });
  };

  init_data_option = (_token, _option) => {
    return new Promise((resolve, reject) => {
      service.getList(_token, _option).then((res, err) => {
        if (res.status) {
          if (res.data.status === 200) {
            resolve({ state: true, data: res.data });
          }
        } else {
          if (res.error.response.status === 401) {
            this.props.history.push("/login");
          }
        }
      });
    });
  };

  addtime = () => {
    // console.log("obj", this.state.objectlist);
    for (var i = 0; i <= this.state.objectlist.length - 1; i++) {
      // console.log(
      //   "time",
      //   this.state.objectlist[i]["time"].substring(11, 13) + ":00"
      // );
      // console.log(
      //   new Date(this.state.objectlist[i]["time"])
      //     .toLocaleTimeString("en-GB")
      //     .substring(0, 2)
      // );
      this.state.objectlist[i]["time"] = new Date(
        this.state.objectlist[i]["time"]
      )
        .toLocaleTimeString("en-GB")
        .substring(0, 2);
    }
    // console.log("list", this.state.objectlist);

    this.timeData();
  };

  timeData = () => {
    Array.prototype.groupBy = function (prop) {
      return this.reduce(function (groups, item) {
        var val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    };
    const tmp = this.state.objectlist.groupBy("time");
    console.log("tmp", tmp);
    const test = [];

    for (var j = 0; j < 24; j++) {
      if (j < 10) {
        var hour = "0" + j;
      } else {
        var hour = j;
      }
      if (tmp[hour] === undefined) {
        var times = 0;
      } else {
        var times = tmp[hour].length;
      }
      test.push({
        time: hour + ":00",
        count: times,
      });
    }
    this.setState({ GroupData: test });
    console.log(test);
    this.spinLoading(false);
  };

  dateCallback = async (date, dateString) => {
    await this.setState({
      date: dateString,
    });
    this.init_data(this.props.auth.token);
  };

  render() {
    const { objectlist, GroupData, loading } = this.state;
    return (
      <Spin spinning={loading} delay={300} tip="Loading...">
        <div>
          <Row gutter={16}>
            <Col span={24}>
              <h2>กราฟช่วงเวลาที่มีผู้ใช้บริการ</h2>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <DatePicker format="YYYY-MM-DD" onChange={this.dateCallback} />
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col span={24}>
              <ChartColumn
                data={GroupData}
                chartdata={chartdata}
                yLine="time"
                width={1100}
                height={400}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
export default connect(mapStateToProps)(CustomerCNTChart);
