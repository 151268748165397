import React, { Component } from "react";
import { Modal, Button } from "antd";
import FormGenerateAdd from "../../components/formGenerateAdd";
import FormGenerateUpdate from "../../components/formGenerateUpdate";

class FormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      formAllState: undefined,
      formState: undefined,
      dataConfig: undefined,
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  initFormModal = (_dataFromParent) => {
    this.setState({
      visible: true,
      formAllState: _dataFromParent.formObj.formAllState,
      formState: _dataFromParent.formObj.formState,
      dataConfig: _dataFromParent,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  funcFormUpdateCallBack = (_dataCallBack) => {
    console.log("funcFormUpdateCallBack on", _dataCallBack);
    this.props.callback(_dataCallBack);
  };

  funcFormAddCallBack = (_dataCallBack) => {
    this.props.callback(_dataCallBack);
  };

  funcFormClickDel = (_id) => {
    this.props.callbackDel(_id);
  };

  render() {
    const { title } = this.props;
    const { formAllState, formState, dataConfig } = this.state;

    return (
      <div>
        <Modal
          title={title}
          width={860}
          visible={this.state.visible}
          footer={false}
          onCancel={this.handleCancel}
        >
          <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
            {
              formState === undefined ? null : (
                <div>
                  {formState === formAllState.Create ? (
                    <FormGenerateAdd
                      dataFromParent={dataConfig}
                      callback={this.funcFormAddCallBack}
                      closeModel={this.handleCancel}
                    />
                  ) : null}

                  {formState === formAllState.Update ? (
                    <FormGenerateUpdate
                      dataFromParent={dataConfig}
                      callbackDel={this.funcFormClickDel}
                      callback={this.funcFormUpdateCallBack}
                    />
                  ) : null}

                  {formState === formAllState.Show ? "Show" : null}
                </div>
              )
              // <FormGenerate callback={this.funcCallBack} formData={form} formState={formState} formAllState={formAllState} />
            }
          </div>
        </Modal>
      </div>
    );
  }
}

export default FormModal;
