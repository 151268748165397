import axios from "axios";
import { BaseUrl } from "../config/configUrl";
import { Key } from "../config/apikey";

const apiVersion = "v2/";

var KKUtransaction = {
  getList: (_token, _type) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + _token,
      apikey: Key,
    };

    return new Promise((resolve, reject) => {
      //axios.get((BaseUrl + apiVersion) + 'sut-transactions/?transaction_type=' + _type + '&limit=1000', { headers: headers }).then((response) => {
      axios
        .get(
          "https://smartbus.kku.ac.th/api/influxdb/query?db=KSTEV&q=select * from COUNTERCAM  where people_total > 0  order by time desc limit 20",
          { headers: headers }
        )
        .then((response) => {
          resolve({ status: true, data: response });
        })
        .catch((error) => {
          resolve({ status: false, error: error });
        });
    });
  },
  getListOption: (_token, _option, _type) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + _token,
      apikey: Key,
    };
    let splitText = _option.filter_string.split("&");
    let bus;
    let time;
    for (let index = 1; index < splitText.length; index++) {
      if (splitText[index].split("=")[0] == "time") {
        time = splitText[index].split("=")[1];
      } else {
        bus = splitText[index].split("=")[1].toUpperCase();
      }
    }
    let url = "";
    if (bus && time) {
      url =
        "https://smartbus.kku.ac.th/bus-people-counter-api/6/?q=people_total > 0 and bus = '" +
        bus +
        "' and time < '" +
        time +
        " 16:59:59' and time > '" +
        time +
        "' order by time desc &limit=" +
        _option.limit +
        "&offset=" +
        _option.offset;
    } else if (bus) {
      url =
        "https://smartbus.kku.ac.th/bus-people-counter-api/6/?q=people_total > 0 and bus = '" +
        bus +
        "' order by time desc &limit=" +
        _option.limit +
        "&offset=" +
        _option.offset;
    } else if (time) {
      url =
        "https://smartbus.kku.ac.th/bus-people-counter-api/6/?q=people_total > 0 and time < '" +
        time +
        " 16:59:59' and time >= '" +
        time +
        "' order by time desc &limit=" +
        _option.limit +
        "&offset=" +
        _option.offset;
    } else {
      url =
        "https://smartbus.kku.ac.th/bus-people-counter-api/6/?q=people_total > 0 order by time desc &limit=" +
        _option.limit +
        "&offset=" +
        _option.offset;
    }
    if (_type == null) {
      return new Promise((resolve, reject) => {
        //axios.get((BaseUrl + apiVersion) + 'sut-transactions/?limit=' + _option.limit + '&offset=' + _option.offset + _option.filter_string, { headers: headers }).then((response) => {
        axios
          .get(url, { headers: headers })
          .then((response) => {
            resolve({ status: true, data: response });
          })
          .catch((error) => {
            resolve({ status: false, error: error });
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        //axios.get((BaseUrl + apiVersion) + 'sut-transactions/?transaction_type=' + _type + '&limit=' + _option.limit + '&offset=' + _option.offset + _option.filter_string, { headers: headers }).then((response) => {
        axios
          .get(url)
          .then((response) => {
            resolve({ status: true, data: response });
          })
          .catch((error) => {
            resolve({ status: false, error: error });
          });
      });
    }
  },
};

export default KKUtransaction;
